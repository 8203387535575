import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

import { StyledButton } from '../../Button/Button.styled';

export const StyledEditText = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  ${StyledButton} {
    white-space: nowrap;
    padding: 0.5em 1em;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.editText};
  font-family: ${({ theme }) => theme.fontBody};
  flex-grow: 1;
`;

Text.defaultProps = {
  theme: SaberTheme,
};
