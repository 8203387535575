import global from './Globals';

const Alert = {
  alertSuccessText: global.success700,
  alertSuccessBorder: global.success400,
  alertSuccessBg: global.success100,

  alertErrorText: global.error700,
  alertErrorBorder: global.error400,
  alertErrorBg: global.error100,

  alertWarningText: global.warning700,
  alertWarningBorder: global.warning400,
  alertWarningBg: global.warning100,

  alertInfoText: global.secondary800,
  alertInfoBorder: global.secondary400,
  alertInfoBg: global.secondary200,
};

export default Alert;
