const GlobalVariables = {
  black: '#1c1917',
  white: '#f8f7f6',

  grey100: '#f5f5f4',
  grey200: '#e7e5e4',
  grey300: '#d6d3d1',
  grey400: '#a8a29e',
  grey500: '#78716c',
  grey600: '#57534e',
  grey700: '#44403c',
  grey800: '#292524',
  grey900: '#1c1917', // Essentially black at this point

  // greens
  success100: '#ebf9f1',
  success200: '#afe6c7',
  success300: '#74d49c',
  success400: '#38c172',
  success500: '#2d9a5b',
  success600: '#227444',
  success700: '#113a22',

  // yellows
  warning100: '#fefaf0',
  warning200: '#fbeac1',
  warning300: '#f7da93',
  warning400: '#f4ca64',
  warning500: '#c3a250',
  warning600: '#7a6532',
  warning700: '#312814',

  // reds
  error100: '#fceaea',
  error200: '#f1acac',
  error300: '#e76e6e',
  error400: '#dc3030',
  error500: '#b02626',
  error600: '#841d1d',
  error700: '#420e0e',

  fontSizeHeader: '1.5rem',
  fontSizeLarge: '1.25rem',
  fontSizeBase: '1rem',
  fontSizeSmall: '0.875rem',
  fontSizeExtraSmall: '0.75rem',

  fontHeader: 'Poppins, sans-serif',
  fontBody: 'Open Sans, sans-serif',
};

export default GlobalVariables;
