import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import {
  AiOutlineLoading3Quarters,
  AiFillEye,
  AiFillEyeInvisible,
} from 'react-icons/ai';
import { FaExclamationCircle } from 'react-icons/fa';

import {
  Label,
  LoadingIcon,
  PasswordEye,
  Optional,
  StyledFormInput,
  Wrapper,
} from './FormGroup.styled';

import ErrorMessage from '../../ErrorMessage';

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  /** Has the input validation failed? */
  error?: boolean;

  /** The string that will show when input has failed. */
  errorMessage?: string;

  /** Text to describe the input */
  label: string;

  /** Flag to show a loading icon (Used mainly for async validators but can be used for other things) */
  loading?: boolean;

  /** Flag to determine or not to show the error on change or blur */
  showErrorOnChange?: boolean;

  /** Flag to determine whether or not to show the loading icon when in loading state */
  showLoadingIcon?: boolean;

  /** Name of the input */
  name?: string;

  /** Update the validator */
  updateValue?: (value: string | number) => void;

  /** Value of the input */
  value?: string | number;

  /** Theme object provided by <ThemeProvider> */
  theme: object;

  type?: 'password' | 'submit' | 'text' | 'number' | 'date';

  /** Is input required? */
  required?: boolean;

  /** Show error message */
  _activated?: boolean;
}

const FormGroup: React.FC<Props> = (props) => {
  const [hideLabel, setHideLabel] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const labelRef: React.MutableRefObject<null> = useRef(null);
  const loadingRef: React.MutableRefObject<null> = useRef(null);

  const {
    className,
    error,
    errorMessage,
    label,
    loading,
    name,
    required,
    showErrorOnChange,
    showLoadingIcon,
    value,
    theme,
    updateValue,
    _activated,
    ...inputProps
  } = props;

  function onChange(e: React.FormEvent<HTMLInputElement>): void {
    const { value } = e.target as HTMLInputElement;

    setShowError(showErrorOnChange || false);

    if (updateValue) {
      updateValue(value);
    }
  }

  function onFocus(): void {
    setHideLabel(true);
  }

  function onShowPassword(): void {
    setShowPassword(!showPassword);
  }

  function wrapOnBlur(e: React.FormEvent<HTMLInputElement>): void {
    const { value } = e.target as HTMLInputElement;

    setHideLabel(value.length > 0);
    setShowError(true);
  }

  const optional = required ? '' : <Optional>(Optional)</Optional>;

  let labelElement = null;

  if (label) {
    labelElement = (
      <Label htmlFor={name} ref={labelRef}>
        {label}
        {optional}
      </Label>
    );
  }

  const isPassword = inputProps.type && inputProps.type === 'password';
  let passwordEye = null;

  if (isPassword) {
    passwordEye = (
      <PasswordEye onClick={onShowPassword} data-testid="password-eye">
        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
      </PasswordEye>
    );

    // If we are showing the password and this type is password. Hijack the type
    if (showPassword) {
      inputProps.type = 'text';
    }
  }

  useEffect(() => {
    if (value) {
      setHideLabel(true);

      const node = labelRef.current;

      if (node) {
        const label = node as HTMLElement;
        if (!label.classList.contains('label-exit')) {
          label.classList.add('label-exit');
        }
      }
    } else {
      setHideLabel(false);
    }

    if (_activated) {
      setShowError(true);
    }
  }, [error, value, theme, _activated]);

  return (
    <div className="form-input-wrapper">
      <Wrapper>
        <CSSTransition
          appear
          in={hideLabel}
          nodeRef={labelRef}
          timeout={100}
          classNames="label"
        >
          {labelElement}
        </CSSTransition>
        <StyledFormInput
          id={name}
          className={`${className || ''} ${isPassword ? 'password' : ''}`}
          error={showError && error}
          name={name}
          value={value}
          data-testid="form-input"
          onChange={onChange}
          {...inputProps}
          onBlur={wrapOnBlur}
          onFocus={onFocus}
          placeholder={undefined}
        />
        <CSSTransition
          appear
          in={loading && showLoadingIcon}
          nodeRef={loadingRef}
          timeout={100}
          classNames="icon"
        >
          <LoadingIcon>
            <div className="icon-wrapper" ref={loadingRef}>
              <AiOutlineLoading3Quarters />
            </div>
          </LoadingIcon>
        </CSSTransition>
        {passwordEye}
      </Wrapper>
      <ErrorMessage hidden={!(showError && error)}>
        <FaExclamationCircle />
        &nbsp;{errorMessage}
      </ErrorMessage>
    </div>
  );
};
FormGroup.defaultProps = {
  label: '',
  name: '',
  theme: {},
  _activated: false,
};

export default withTheme(FormGroup);
