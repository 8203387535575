import Alert from './Alert';
import Button from './Button';
import Form from './Form';
import Loader from './Loader';
import PhoneInput from './PhoneInput';
import Menu from './Menu';
import Modal from './Modal';
import Steps from './Steps';
import Table from './Table';
import Typography from './Typography';

import Globals from './Globals';

const Theme = {
  ...Globals,
  ...Alert,
  ...Button,
  ...Form,
  ...Loader,
  ...Menu,
  ...Modal,
  ...PhoneInput,
  ...Steps,
  ...Table,
  ...Typography,
};

export default Theme;
