import React from 'react';

import { ButtonGroup } from './ButtonGroup.styled';

interface Props {
  children: React.ReactNode;
}

function Group({ children }: Props) {
  return <ButtonGroup>{children}</ButtonGroup>;
}

export default Group;
