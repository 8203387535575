import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import SaberTheme from '../Themes/SaberLight';

export const Close = styled.button`
  background-color: ${({ theme }) => theme.modalCloseBg};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.modalCloseText};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;

  font-size: 2em;

  width: 36px;
  height: 36px;
`;

export const Footer = styled.div`
  text-align: right;

  button {
    margin-left: 0.5em;
  }
`;

export const ScreenHider = styled.div`
  background-color: rgba(0, 0, 0, 0.75);

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1399;

  width: 100vw;
  height: 100vh;

  visibility: hidden;

  &.visible {
    visibility: visible;
  }
`;

export const StyledModal = styled.div`
  background-color: ${({ theme }) => theme.modalBg};
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.4);

  padding: 1em;
`;

export const Wrapper = styled.div`
  min-width: 300px;
  max-width: 300px;

  opacity: 0;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  visibility: hidden;

  z-index: 1400;

  &.modal-enter {
    opacity: 0;
  }

  &.modal-enter-active {
    opacity: 1;
    transition: opacity 200ms;
    visibility: visible;
  }
  &.modal-enter-done {
    opacity: 1;
    visibility: visible;
  }

  &.modal-exit {
    opacity: 1;
    visibility: visible;
  }

  &.modal-exit-active {
    opacity: 0;
    transition: opacity 200ms;
    visibility: visible;
  }

  ${breakpoint('mobile')`
    min-width: 90vw;
    max-width: 90vw;
  `}

  ${breakpoint('tablet')`
    min-width: 80vw;
    max-width: 80vw;
  `}

  ${breakpoint('desktop')`
    top: 25%;

    min-width: 900px;
    max-width: 900px;
  `}
`;

// ==================== Default Props ====================

Close.defaultProps = {
  theme: SaberTheme,
};

StyledModal.defaultProps = {
  theme: SaberTheme,
};
