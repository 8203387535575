import styled from 'styled-components';
import Global from '../Themes/Globals';
import SaberTheme from '../Themes/SaberLight';

export const Content = styled.div`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.9rem;
  padding: 1rem 0.75rem;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;

  min-width: 28px;
  width: 28px;
`;

export const Container = styled.div`
  display: flex;

  background-color: #d3d3d3;

  ${Icon} {
    color: white;
    background-color: #343434;
  }

  ${Content} {
    color: #454545;
  }

  &.success {
    background-color: ${({ theme }) => theme.alertSuccessBg};
    ${Icon} {
      color: white;
      background-color: ${({ theme }) => theme.alertSuccessBorder};
    }
    ${Content} {
      color: ${({ theme }) => theme.alertSuccessText};
    }
  }

  &.error {
    background-color: ${({ theme }) => theme.alertErrorBg};
    ${Icon} {
      color: white;
      background-color: ${({ theme }) => theme.alertErrorBorder};
    }
    ${Content} {
      color: ${({ theme }) => theme.alertErrorText};
    }
  }

  &.warning {
    background-color: ${({ theme }) => theme.alertWarningBg};
    ${Icon} {
      color: white;
      background-color: ${({ theme }) => theme.alertWarningBorder};
    }
    ${Content} {
      color: ${({ theme }) => theme.alertWarningText};
    }
  }

  &.info {
    background-color: ${({ theme }) => theme.alertInfoBg};
    ${Icon} {
      color: white;
      background-color: ${({ theme }) => theme.alertInfoBorder};
    }
    ${Content} {
      color: ${({ theme }) => theme.alertInfoText};
    }
  }
`;

// ================================================================================================
// Default Props
// ================================================================================================

Content.defaultProps = {
  theme: {
    fontHeader: Global.fontHeader,
  },
};

Container.defaultProps = {
  theme: SaberTheme,
};
