import styled from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.error400};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};

  height: 22px;
  opacity: 1;
  overflow: hidden;
  padding: 0.5em 0;
  transition: all 0.1s ease-out;

  &.hidden {
    opacity: 0;
  }
`;

StyledErrorMessage.defaultProps = {
  theme: SaberTheme,
};
