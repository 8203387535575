import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

export const StyledPargraph = styled.p`
  color: ${({ theme }) => theme.fontColour};

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};
  letter-spacing: 0.25px;
  line-height: 1.5rem;

  margin: 0.5rem 0 1.5rem 0;

  @media only screen and (min-width: 1024px) {
    line-height: 1.75rem;
  }

  @media only screen and (min-width: 1280px) {
    line-height: 2rem;
  }
`;

StyledPargraph.defaultProps = {
  theme: SaberTheme,
};
