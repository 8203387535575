import React from 'react';
import styled from 'styled-components';

import StepsItem from './StepsItem';

interface Props {
  /* Tracks the step we are at */
  progress: number;

  children: React.ReactNode;
}

const StyledStep = styled.div`
  display: flex;
`;

const Steps = ({ children, progress }: Props) => {
  if (children) {
    const numOfChildren = React.Children.count(children);

    const clonedChildren = React.Children.map(
      children,
      (child: React.ReactNode, index: number) =>
        React.cloneElement(child as React.ReactElement, {
          index: index + 1,
          showBorder: index + 1 !== numOfChildren,
          progress: progress === index,
          completed: progress >= index + 1,
          disabled: index > progress,
        }),
    );

    return <StyledStep>{clonedChildren}</StyledStep>;
  }

  return null;
};
Steps.Item = StepsItem;
Steps.defaultProps = {
  progress: 0,
  children: null,
};

export default Steps;
