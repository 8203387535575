import styled from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

export const inputRadius = '3px';

interface PhoneInput {
  disabled?: boolean;
}

export const StyledPhoneInput = styled.div<PhoneInput>`
  background-color: ${({ theme, disabled }) => {
    if (disabled) return theme.formGroupBgDisabled;
    return theme.formGroupBg;
  }};
  border: 1px solid
    ${({ theme }) => {
      if (false) {
        return theme.formGroupBorderError;
      }
      return theme.formGroupBorder;
    }};
  border-radius: ${inputRadius};

  padding: 1.5em 0.75em 0.5em 0.7em;
  position: relative;

  .PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
  }

  .PhoneInputInput {
    background: none;
    border: none;
    color: ${({ theme }) => theme.phoneInputText};
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${({ theme }) => theme.fontSizeBase};
    flex: 1;
    min-width: 0;
    padding: 0.5rem;
    outline: none;
  }

  .PhoneInputCountryIcon {
    width: calc(1rem * 1.5);
    height: 1rem;
  }

  .PhoneInputCountryIcon--square {
    width: 1rem;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: var(0.65);
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35rem;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3rem;
    height: 0.3rem;
    margin-left: 0.35rem;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.formGroupText};
  display: block;

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeSmall};

  padding-bottom: 0.35em;
  top: 0.25em;
  transform: translateY(0);

  left: 0.75em;
  position: absolute;
`;

export const Optional = styled.span`
  color: grey;
  font-family: ${({ theme }) => theme.fontBody};
  padding-left: 0.25em;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.formGroupTextError};

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeSmall};

  height: 20px;
  padding: 0.5em 0;
  overflow: hidden;
  transition: all 0.1s ease-out;
  opacity: 1;

  &[data-hidden='true'] {
    opacity: 0;
  }
`;

// ================================================================================
// Default Props
// ================================================================================

StyledPhoneInput.defaultProps = {
  theme: SaberTheme,
};

Label.defaultProps = {
  theme: SaberTheme,
};

Optional.defaultProps = {
  theme: SaberTheme,
};

ErrorMessage.defaultProps = {
  theme: SaberTheme,
};
