import global from './Globals';

const PhoneInput = {
  phoneInputBg: 'transparent',
  phoneInputBgDisabled: global.grey200,
  phoneInputBorder: 'rgba(0, 0, 0, 0.25)',
  phoneInputText: global.black,
  phoneInputListBg: 'white',
  phoneInputListText: global.black,
  phoneInputListHover: global.primary300,
  phoneInputListHighlight: global.primary600,
  phoneInputListHighlightText: global.white,
};

export default PhoneInput;
