import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { AiOutlineClose } from 'react-icons/ai';

import Button from '../Button';

import {
  Close,
  Footer,
  ScreenHider,
  StyledModal,
  Wrapper,
} from './Modal.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** When the user cancels the modal (Clicks cancel, the close button or clicks away) */
  cancel: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;

  /** When the user specifically clicks the confirm button */
  confirm: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /** Element for the footer of the modal */
  footer: React.ReactNode;

  /** Flag to determine if the modal is visible */
  visible: boolean;
}

function Modal(props: Props) {
  const {
    className,
    cancel,
    confirm,
    children,
    footer,
    visible,
    ...divProps
  } = props;

  const nodeRef = React.useRef(null);

  let footerElm = footer;

  if (!footerElm) {
    footerElm = (
      <Footer>
        <Button ghost onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={confirm}>Confirm</Button>
      </Footer>
    );
  }

  return (
    <React.Fragment>
      <ScreenHider
        className={visible ? 'visible' : ''}
        onClick={cancel}
        data-testid="modal-screen"
      />
      <CSSTransition
        in={visible}
        timeout={200}
        classNames="modal"
        nodeRef={nodeRef}
      >
        <Wrapper className="modal__wrapper" ref={nodeRef}>
          <Close onClick={cancel} data-testid="modal-close">
            <AiOutlineClose />
          </Close>
          <StyledModal className={className || ''} {...divProps}>
            {children}
            {footerElm}
          </StyledModal>
        </Wrapper>
      </CSSTransition>
    </React.Fragment>
  );
}

Modal.defaultProps = {
  cancel: undefined,
  confirm: undefined,
  footer: undefined,
  visible: false,
};

export default Modal;
