import React, { useContext } from 'react';
import Wrapper from './MenuItem.styled';
import MenuContext from '../Menu.Context';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /** Where should this link to? */
  to?: string;
}

/**
 * A custom menu item linker. If you want this to be controlled programmically, just use the to prop
 * However, if you want to link to an external website, just specific a href prop.
 *
 * It is important to note that the href prop will override the to prop.
 *
 * @param {string} to Where should this link to?
 * @param {string} href External link
 */
function MenuItem({
  className,
  children,
  to,
  ...aProps
}: Props): React.ReactElement {
  const context = useContext(MenuContext);
  const { activeId, changeActiveId } = context;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!aProps.href) {
      e.preventDefault();
      if (changeActiveId) changeActiveId(to || '');
    }
  };

  return (
    <Wrapper
      className={`menu-item ${className || ''} ${
        activeId === to ? 'active' : ''
      }`}
      {...aProps}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
}

export default MenuItem;
