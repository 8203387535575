import styled from 'styled-components';
import MenuItem from '../MenuItem/MenuItem.styled';
import SaberTheme from '../../Themes/SaberLight';

export const DropdownArea = styled.div`
  display: none;

  left: 0.5rem;
  padding: 0rem 1rem;
  top: 100%;

  z-index: 1000;

  ${MenuItem} {
    color: rgba(255, 255, 255, 0.75);

    &.active {
      color: ${({ theme }) => theme.menuDropdownTextActive};
    }
  }

  @media only screen and (min-width: 1200px) {
    position: absolute;
    background-color: ${({ theme }) => theme.menuDropdownBg};
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-bottom: 4px solid ${({ theme }) => theme.primary400};
    border-top: 0;

    padding: 0.5rem 3rem 0.5rem 0.5rem;

    ${MenuItem} {
      color: ${({ theme }) => theme.menuDropdownText};

      &::after {
        display: none;
      }

      &:hover {
        color: ${({ theme }) => theme.menuDropdownTextHover};
      }

      &.active {
        color: ${({ theme }) => theme.menuDropdownTextActive};
      }
    }
  }
`;

export const Chevron = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  transition: transform 100ms;

  margin-left: -0.45rem;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;

  ${MenuItem} {
    height: calc(100% - 1rem);
  }

  &.expanded {
    border-bottom: 1px solid grey;

    ${Chevron} {
      transform: rotate(90deg);
    }

    ${DropdownArea} {
      display: block;
    }

    @media only screen and (min-width: 1200px) {
      border-bottom: none;
    }
  }
`;

// ================================================================================================
// Default Props
// ================================================================================================

DropdownArea.defaultProps = {
  theme: SaberTheme,
};
