import React from 'react';

import { StyledTypography } from './Typography.styled';

import EditText from './EditText';
import Header from './Header';
import Paragraph from './Paragraph';

const Typography = ({
  children,
  ...spanProps
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <StyledTypography {...spanProps}>{children}</StyledTypography>;
};

Typography.EditText = EditText;
Typography.Header = Header;
Typography.Paragraph = Paragraph;

export default Typography;
