import styled from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

const iconSize = '32px';

interface Props {
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
  progress?: boolean;
  showBorder?: boolean;
}

export const StyledStepsItem = styled.div<Props>`
  border-bottom: 2px solid
    ${({ disabled, error, theme }): string => {
      if (error) {
        return theme.error400;
      } else if (disabled) {
        return theme.stepsDisabledColour;
      } else {
        return theme.stepsColour;
      }
    }};
  padding-bottom: 0.5em;

  cursor: ${({ disabled, progress }): string =>
    disabled || progress ? 'default' : 'pointer'};

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  overflow: hidden;

  margin-right: ${({ showBorder }): string => (showBorder ? '0.5em' : '')};
`;

export const Icon = styled.div<Props>`
  border-radius: 50%;

  color: white;

  width: ${iconSize};
  height: ${iconSize};
  min-width: ${iconSize};
  min-height: ${iconSize};

  font-family: ${({ theme }) => theme.fontBody};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 0.5em;

  background-color: ${({ completed, error, theme }): string => {
    if (completed) {
      return theme.stepsColour;
    } else if (error) {
      return theme.error100;
    }
    return 'transparent';
  }};
  color: ${({ completed, disabled, error, theme }): string => {
    if (completed) {
      return 'white';
    } else if (error) {
      return theme.error400;
    } else if (disabled) {
      return theme.stepsDisabledColour;
    } else {
      return theme.stepsTextColour;
    }
  }};
  border: 2px solid
    ${({ disabled, error, theme }): string => {
      if (error) {
        return theme.error;
      } else if (disabled) {
        return theme.stepsDisabledColour;
      } else {
        return theme.stepsColour;
      }
    }};
`;

export const Description = styled.div<Props>`
  display: inline-block;
  position: relative;

  font-family: ${({ theme }) => theme.fontBody};

  color: ${({ completed, disabled, error, theme }): string => {
    if (completed) {
      return theme.stepsColour;
    } else if (error) {
      return theme.error400;
    } else if (disabled) {
      return theme.stepsDisabledColour;
    } else {
      return theme.stepsTextColour;
    }
  }};

  padding-right: ${({ showBorder }): string => (showBorder ? '0.5em' : '')};
`;

// =========================================================================
// Default Props
// =========================================================================

StyledStepsItem.defaultProps = {
  theme: SaberTheme,
};

Icon.defaultProps = {
  theme: SaberTheme,
};

Description.defaultProps = {
  theme: SaberTheme,
};
