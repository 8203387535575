import global from './Globals';

const Steps = {
  stepsColour: global.primary400,
  stepsTextColour: global.black,
  stepsDisabledColour: global.grey200,
  stepsDividerColour: global.grey200,
};

export default Steps;
