import styled, { keyframes } from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

const ANIM_TIME = '100ms';
const LOADING_ANIM = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;
export const inputRadius = '3px';

export const Optional = styled.span`
  color: grey;
  font-family: ${({ theme }) => theme.fontBody};
  padding-left: 0.25em;
`;

interface LabelProps {
  ref: React.MutableRefObject<null>;
}

export const Label = styled.label<LabelProps>`
  color: ${({ theme }) => theme.grey500};
  cursor: text;
  display: block;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};
  top: 50%;
  transform: translateY(-50%);

  left: 0.75em;
  position: absolute;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &.label-enter-active {
    transition: all ${ANIM_TIME} ease-out;
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25em;
    transform: translateY(0);
  }

  &.label-enter-done {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25em;
    transform: translateY(0);
  }

  &.label-exit-enter {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25em;
    transform: translateY(0);
  }

  &.label-exit {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25em;
    transform: translateY(0);
  }

  &.label-exit-active {
    transition: all ${ANIM_TIME} ease-out;
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeBase};
    top: 50%;
    transform: translateY(-50%);
  }

  &.label-exit-done {
    font-size: ${({ theme }) => theme.fontSizeBase};
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface FormInputProps {
  error?: boolean;
}

export const StyledFormInput = styled.input<FormInputProps>`
  background-color: ${({ theme }) => theme.formGroupBg};
  border: 1px solid
    ${({ theme, error }) => {
      if (error) {
        return theme.formGroupBorderError;
      }
      return theme.formGroupBorder;
    }};
  border-radius: ${inputRadius};
  box-sizing: border-box;

  color: ${({ theme }) => theme.formGroupInput};

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};
  padding: 1.5em 0.75em 0.5em 0.7em;
  width: 100%;

  &.password {
    border-radius: ${inputRadius} 0 0 ${inputRadius};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.formGroupFocusBorder};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.formGroupFocusGlow};
    outline: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.formGroupBgDisabled};
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const LoadingIcon = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  transform: translateY(-50%);

  .icon-wrapper {
    transform: scale(0);

    &.icon-enter-active {
      transform: scale(1);
      transition: all 100ms ease-out;
    }

    &.icon-enter-done {
      transform: scale(1);
    }

    &.icon-exit {
      transform: scale(1);
    }

    &.icon-exit-active {
      transform: scale(0);
      transition: all 100ms ease-out;
    }

    svg {
      animation: ${LOADING_ANIM} 1.1s infinite linear;
      color: ${({ theme }) => theme.primary400};
      font-size: 1.5em;
    }
  }
`;

export const PasswordEye = styled.div`
  background-color: ${({ theme }) => theme.primary400};
  border-radius: 0 ${inputRadius} ${inputRadius} 0;
  color: ${({ theme }) => theme.eyeColour};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizeLarge};

  display: flex;
  align-items: center;

  padding: 0 0.25em;

  &:hover {
    color: ${({ theme }) => theme.eyeColourHover};
  }
`;

// =========================================================================
// Default Props
// =========================================================================
Optional.defaultProps = {
  theme: SaberTheme,
};

PasswordEye.defaultProps = {
  theme: SaberTheme,
};

Label.defaultProps = {
  theme: SaberTheme,
};

StyledFormInput.defaultProps = {
  theme: SaberTheme,
};

LoadingIcon.defaultProps = {
  theme: SaberTheme,
};

LoadingIcon.defaultProps = {
  theme: SaberTheme,
};
