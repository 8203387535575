import React from 'react';

import { StyledErrorMessage } from './ErrorMessage.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** Should the error message be hidden? */
  hidden?: boolean;
}

const ErrorMessage = ({ children, className, hidden }: Props) => {
  return (
    <StyledErrorMessage
      className={`${className || ''} ${hidden ? 'hidden' : ''}`}
      data-testid="error-message"
    >
      {children}
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
