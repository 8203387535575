import React from 'react';

import { Header } from './FormHeader.styled';

interface Props {
  children: React.ReactNode;
}

export default function FormHeader({ children }: Props) {
  return <Header>{children}</Header>;
}
