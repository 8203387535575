import React from 'react';

import ButtonGroup from './ButtonGroup';

import {
  BasicBtnProps,
  BtnLoader,
  StyledButton,
  GhostButton,
  Link,
} from './Button.styled';
import { StyledLoader } from '../Loader/Loader.styled';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  /** Disables the button */
  disabled?: boolean;

  /** Toggle if the button should fill the entire container */
  fill?: boolean;

  /** Toggle if the button should have a ghost appearance */
  ghost?: boolean;

  /** Toggle where or not the button should be rendered as a link */
  link?: boolean;

  /** Replace the text with a loading icon and disable the button */
  loading?: boolean;

  /** The type of button it is: button, submit, reset */
  type: 'button' | 'submit' | 'reset' | undefined;

  /** Size of the button */
  size?: 'xs' | 's' | 'm' | 'lg' | 'xlg';
}

const Button = (props: Props) => {
  const { children, fill, ghost, loading, link, type, ...otherProps } = props;

  let parsedType: 'button' | 'submit' | 'reset' | undefined = 'button';

  switch (type) {
    case 'submit':
      parsedType = 'submit';
      break;
    case 'reset':
      parsedType = 'reset';
      break;
    default:
      parsedType = 'button';
  }

  const btnProps: BasicBtnProps = {
    ...otherProps,
    type: parsedType,
    fill: fill ? 1 : 0,
    className: loading ? 'loading' : '',
  };

  if (loading) {
    btnProps.disabled = true;
  }

  const loader = (
    <BtnLoader className={loading ? 'loading' : ''}>
      <StyledLoader size="1.5rem" />
    </BtnLoader>
  );

  let btnElement = (
    <StyledButton {...btnProps}>
      {children}
      {loader}
    </StyledButton>
  );

  if (ghost) {
    btnElement = (
      <GhostButton {...btnProps}>
        {children}
        {loader}
      </GhostButton>
    );
  } else if (link) {
    btnElement = <Link {...btnProps}>{children}</Link>;
  }

  return btnElement;
};

Button.Group = ButtonGroup;
Button.defaultProps = {
  type: 'button',
};

export default Button;
