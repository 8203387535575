import styled from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

export const StyledTypography = styled.span`
  color: ${({ theme }) => theme.fontColour};

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};
`;
StyledTypography.defaultProps = {
  theme: SaberTheme,
};
