import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

export const Header = styled.div`
  color: ${({ theme }) => theme.formHeaderText};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: ${({ theme }) => theme.fontSizeHeader};

  padding: 0.5em 0;
`;
Header.defaultProps = {
  theme: SaberTheme,
};
