import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

const containerSize = {
  xlg: '1300px',
  lg: '1160px',
  md: '768px',
  sm: '456px',
  xs: '380px',
};

interface ContainerProps {
  size: string;
}
export const StyledContainer = styled.div<ContainerProps>`
  margin: auto;
  max-width: ${({ size }): string => containerSize[size]};
  padding: 0.5em 1em;

  ${breakpoint('tablet')`
    border-radius: 10px;
    padding: 1em 2em;

    &.border {
      border: 1px solid grey;
    }
  `}
`;
