import styled from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

import {
  BasicButton,
  StyledButton,
  GhostButton,
  borderRadius,
  borderWidth,
} from './Button.styled';

export const ButtonGroup = styled.div`
  ${BasicButton} {
    border-radius: 0;

    &:first-child {
      border-radius: ${borderRadius} 0 0 ${borderRadius};
    }

    &:last-child {
      border-radius: 0 ${borderRadius} ${borderRadius} 0;
    }
  }

  ${GhostButton} {
    border-right: 0;

    &:last-child {
      border: ${borderWidth} solid ${({ theme }) => theme.btnGhost};
    }

    &:hover:not(:disabled):not(.loading):not(:last-child) {
      border-right: 0;
    }
  }

  ${StyledButton} {
    border: ${borderWidth} solid ${({ theme }) => theme.btnGhost};
    border-right: 0;

    &:last-child {
      border: ${borderWidth} solid ${({ theme }) => theme.btnGhost};
    }

    &:hover:not(:disabled):not(.loading) {
      border: ${borderWidth} solid ${({ theme }) => theme.btnGhost};
      background-color: ${({ theme }) => theme.btnBg};
    }

    &:hover:not(:disabled):not(.loading):not(:last-child) {
      border-right: 0;
    }
  }
`;
ButtonGroup.defaultProps = {
  theme: SaberTheme,
};
