import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';
import { MenuBreakpoint } from '../Menu.styled';

export const BtnHamburger = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-left: 1em;
  padding: 0;
  position: absolute;

  height: 22px;
  width: 32px;

  z-index: 12000;

  @media only screen and (min-width: ${MenuBreakpoint}) {
    display: none;
  }

  span {
    background-color: ${({ theme }) => theme.menuHamburgerColor};
    border-radius: 3px;
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    transition: all 125ms ease-in-out;
    transform-origin: center center;

    &:nth-child(1) {
      top: 0;
      transform: rotate(0deg);
    }

    &:nth-child(2) {
      opacity: 1;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
    }

    &:nth-child(3) {
      top: 100%;
      transform: translateY(-100%) rotate(0deg);
    }
  }

  &.open {
    span:nth-child(1) {
      background-color: ${({ theme }) => theme.menuHamburgerColorActive};
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    span:nth-child(2) {
      background-color: ${({ theme }) => theme.menuHamburgerColorActive};
      opacity: 0;
      width: 0;
    }

    span:nth-child(3) {
      background-color: ${({ theme }) => theme.menuHamburgerColorActive};
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
`;
BtnHamburger.defaultProps = {
  theme: SaberTheme,
};
