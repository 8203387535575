import styled, { css } from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

const fontSize = ['2.1rem', '1.9rem', '1.5rem', '1.25rem', '1.125rem'];

const HeaderBase = css`
  color: ${({ theme }) => theme.fontColour};
  font-family: ${({ theme }) => theme.fontHeader};
  font-weight: normal;
  margin: 0.5rem 0 1.5rem;
`;

export const StyledH1 = styled.h1`
  ${HeaderBase}
  font-size: ${fontSize[0]};
`;

export const StyledH2 = styled.h2`
  ${HeaderBase}
  font-size: ${fontSize[1]};
`;

export const StyledH3 = styled.h3`
  ${HeaderBase}
  font-size: ${fontSize[2]};
`;

export const StyledH4 = styled.h4`
  ${HeaderBase}
  font-size: ${fontSize[3]};
`;

export const StyledH5 = styled.h5`
  ${HeaderBase}
  font-size: ${fontSize[4]};
`;

// =====================================================
// =================== DEFAULT PROPS ===================
// =====================================================

StyledH1.defaultProps = {
  theme: SaberTheme,
};

StyledH2.defaultProps = {
  theme: SaberTheme,
};

StyledH3.defaultProps = {
  theme: SaberTheme,
};

StyledH4.defaultProps = {
  theme: SaberTheme,
};

StyledH5.defaultProps = {
  theme: SaberTheme,
};
