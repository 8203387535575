import global from './Globals';

const Menu = {
  menuBgColour: global.black,
  menuItemActive: global.primary400,
  menuItemColour: global.grey300,
  menuItemHover: global.primary200,
  menuButtonColour: global.white,

  menuDivider: global.grey300,
  menuShadow: '5px 5px 10px #e8e8e8, -5px -5px 10px #ffffff',

  menuDropdownBg: global.white,
  menuDropdownText: global.black,
  menuDropdownTextHover: global.primary600,
  menuDropdownTextActive: global.primary400,

  menuMobileBg: global.black,
  menuMobileWidth: '250px',

  menuHamburgerColorActive: global.white,
  menuHamburgerColor: global.white,
};

export default Menu;
