import React from 'react';

import { StyledPargraph } from './Paragraph.styled';

function Paragraph({
  children,
  ...pProps
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return <StyledPargraph {...pProps}>{children}</StyledPargraph>;
}

export default Paragraph;
