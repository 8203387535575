import React from 'react';
import { FaPen } from 'react-icons/fa';

import { Text, StyledEditText } from './EditText.styled';
import { StyledButton } from '../../Button/Button.styled';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  /** Button text */
  editLabel?: React.ReactNode;

  /** When the button is clicked */
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /** Disables the button */
  disabled?: boolean;
}

function EditText(props: Props) {
  const { children, onClick, editLabel, disabled, ...divProps } = props;

  const label = editLabel || (
    <React.Fragment>
      <FaPen />
      &nbsp;&nbsp;Edit
    </React.Fragment>
  );

  return (
    <StyledEditText {...divProps}>
      <Text>{children}</Text>
      <StyledButton type="button" onClick={onClick} disabled={disabled}>
        {label}
      </StyledButton>
    </StyledEditText>
  );
}

EditText.defaultProps = {
  disabled: false,
  editLabel: '',
  onClick: undefined,
};

export default EditText;
