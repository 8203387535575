import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

import SaberTheme from '../Themes/SaberLight';

export const StyledTable = styled.table`
  border-collapse: collapse;
  font-family: ${({ theme }) => theme.fontHeader};

  text-align: left;
  width: 100%;

  thead {
    color: ${({ theme }) => theme.tableHeaderText};

    th {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  tbody {
    background-color: ${({ theme }) => theme.tableBodyBg};
  }

  td,
  th {
    white-space: nowrap;
    padding: 1.2em 1em;
  }
`;

export const Wrapper = styled.div`
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.1);

  overflow-x: scroll;

  ${breakpoint('tablet')`
    overflow-x: auto;
  `}
`;

export const Chevron = styled.div`
  display: flex;

  &.selected-up {
    transform: rotate(-90deg);
  }

  &.selected-down {
    transform: rotate(90deg);
  }
`;

export const THContent = styled.div`
  display: flex;
  align-items: center;
`;

// ================================================
// Default Props
// ================================================

StyledTable.defaultProps = {
  theme: SaberTheme,
};
