import React from 'react';
import PropType from 'prop-types';

import { LoaderContainer, StyledLoader, Message } from './Loader.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** Text to display to the user while it is spinning */
  message: string;
  size?: string;
}

const Loader = ({ className, message, size }: Props) => {
  return (
    <LoaderContainer className={className}>
      <StyledLoader size={size || '4em'} />
      <Message>{message}</Message>
    </LoaderContainer>
  );
};

Loader.propTypes = {
  /** Text to display to the user while it is spinning */
  message: PropType.string,
};
Loader.defaultProps = {
  message: '',
};

export default Loader;
