import styled, { keyframes } from 'styled-components';
import SaberTheme from '../Themes/SaberLight';

const loaderWidth = '0.5em';

const loaderAnim = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;

export const LoaderContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

interface StyledLoaderProp {
  size: string;
}

export const StyledLoader = styled.div<StyledLoaderProp>`
  display: inline-block;
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  animation: ${loaderAnim} 1.1s infinite linear;

  border-top: ${loaderWidth} solid ${({ theme }) => theme.loaderSpinBg};
  border-right: ${loaderWidth} solid ${({ theme }) => theme.loaderSpinBg};
  border-bottom: ${loaderWidth} solid ${({ theme }) => theme.loaderSpinBg};
  border-left: ${loaderWidth} solid ${({ theme }) => theme.loaderSpin};

  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  max-width: ${({ size }) => size};
  max-height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.loaderTextColour};
  font-family: ${({ theme }) => theme.fontHeader};
  margin-left: 0.75em;
`;

// ===============================================================
// Default Props
// ===============================================================

StyledLoader.defaultProps = {
  theme: SaberTheme,
};
Message.defaultProps = {
  theme: SaberTheme,
};
