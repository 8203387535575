import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

const MenuItemStyled = styled.a`
  color: ${({ theme }) => theme.menuItemColour};
  cursor: pointer;

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 0.9rem;

  padding: 0.5rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.menuItemHover};
  }

  &.active {
    color: ${({ theme }) => theme.menuItemActive};
  }
`;

MenuItemStyled.defaultProps = {
  theme: SaberTheme,
};

export default MenuItemStyled;
