import React from 'react';

interface Props {
  /** The initial value of the input */
  defaultValue?: string;

  /** Inform the parent component (Should be a Form) that this input is invalid */
  informInvalid: (name: string, invalid: boolean) => void;

  /** Name of the input, will override the input's name */
  name: string;

  /** When the value of the input has changed */
  onChange: (name: string, value: string) => void;

  /** Array of validators in the form */
  validators: Array<{
    validator: (data: string) => boolean | Promise<boolean>;
    errorMessage: string;
  }>;

  /** Is this required? */
  required?: boolean;

  /** Type of input */
  type: string;

  /** Take a sneak peak at what the value is for this validator */
  peek?: (name: string, value: string) => void;

  /** Has this validator been activated? */
  _activated: boolean;

  /** Trigger the cleaning of the values */
  _cleared?: boolean;

  children: React.ReactNode;
}

interface State {
  // Indicate if the FormGroup is loading
  loading: boolean;

  // Check if the FormGroup has failed
  validationFailed: boolean;
  validationMessage: string;
  value: string | null | undefined;
}

class Validator extends React.Component<Props, State> {
  static defaultProps = {
    defaultValue: '',
    onChange: undefined,
    informInvalid: undefined,
    validators: [],
    required: false,
    type: 'text',
    peek: undefined,
    _activated: false,
  };

  activeValidators: number;
  mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.activeValidators = 0;
    this.mounted = true;

    this.state = {
      loading: false,
      validationFailed: false,
      validationMessage: '',
      value: props.defaultValue,
    };
  }

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = (prevProps: Props): void => {
    const { _activated, _cleared, informInvalid, onChange, name } = this.props;

    if (prevProps._activated !== _activated && _activated) {
      const { value } = this.state;
      this.updateValue(value || '');
    } else if (prevProps._activated !== _activated && !_activated) {
      this.setState({
        validationFailed: false,
        validationMessage: '',
      });
    }

    if (prevProps._cleared !== _cleared && _cleared) {
      informInvalid(name, true);
      onChange(name, '');
      this.setState({ value: '' });
    }
  };

  finishValidation = (
    testedValue: string,
    success: boolean,
    message: string,
  ): void => {
    const { informInvalid, name, required } = this.props;
    const { value } = this.state;

    if (!this.mounted) {
      // If the component is no longer mounted we can skip validation
      return;
    }

    if (value && value === testedValue) {
      // This is ran later in the code and only runs if the validated data matches the current one
      this.setState({
        validationFailed: !success,
        validationMessage: message,
      });
    }

    if (!value && !required) {
      // If the current value is empty and not required, it is not an error anymore
      this.setState({ validationFailed: false });
    }

    if (this.activeValidators === 0) {
      this.setState({ loading: false });
      if (informInvalid) informInvalid(name, !success);
    } else {
      if (informInvalid) informInvalid(name, true);
    }
  };

  validate = async (value: string) => {
    const { validators } = this.props;
    this.setState({ loading: true });

    let success = true;
    let message = '';

    for (const v of validators) {
      this.activeValidators++;
      success = await Promise.resolve(v.validator(value));
      this.activeValidators--;

      if (!success) {
        message = v.errorMessage;
        break;
      }
    }

    this.finishValidation(value, success, message);
  };

  updateValue = (value: string): void => {
    const { informInvalid, onChange, peek, name, required } = this.props;

    if (peek) peek(name, value);

    // Anytime a value is updated, we are assuming it is invalid until proven valid
    if (informInvalid) informInvalid(name, true);

    if (onChange) onChange(name, value);
    this.setState({ value });

    if (!value && required) {
      this.setState({
        validationFailed: true,
        validationMessage: 'This is a required field.',
      });
      if (informInvalid) {
        informInvalid(name, true);
      }
    } else {
      Promise.resolve().then(() => {
        this.validate(value);
      });
    }
  };

  render(): React.ReactNode {
    const { children, name, required, type, _activated } = this.props;
    const { loading, validationFailed, validationMessage, value } = this.state;

    const clonedChildren = React.Children.map(
      children,
      (child: React.ReactNode): React.ReactNode => {
        if (type === 'checkbox') {
          return React.cloneElement(child as React.ReactElement, {
            updateValue: this.updateValue,
            name,
            type,
            value,
            required,
          });
        }

        return React.cloneElement(child as React.ReactElement, {
          updateValue: this.updateValue,
          name,
          error: validationFailed,
          errorMessage: validationMessage,
          loading,
          type,
          value,
          required,
          _activated,
        });
      },
    );

    return clonedChildren;
  }
}

export default Validator;
