import styled from 'styled-components';
import { StyledLoader } from '../Loader/Loader.styled';
import SaberTheme from '../Themes/SaberLight';

export const borderWidth = '2px';
export const borderRadius = '0px';
const loaderWidth = '0.4em';

const sizes = {
  xs: '0.25rem 1rem',
  s: '0.375rem 1.25rem',
  m: '0.5rem 1.5rem',
  lg: '0.625rem 1.75rem',
  xlg: '0.75rem 2rem',
};

export interface BasicBtnProps {
  className?: string;
  disabled?: boolean;
  fill?: number;
  size?: 'xs' | 's' | 'm' | 'lg' | 'xlg';
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const BasicButton = styled.button<BasicBtnProps>`
  padding: ${({ size }) => (size ? sizes[size] : `0.5rem 1.5rem`)};
  border-radius: ${borderRadius};
  cursor: pointer;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: ${({ theme }) => theme.fontSizeBase};

  outline: none;

  position: relative;

  width: ${({ fill }) => (fill ? '100%' : 'auto')};

  &:disabled {
    cursor: default;
  }

  &.loading {
    color: transparent;
  }
`;

export const StyledButton = styled(BasicButton)`
  background: ${({ theme }) => theme.btnBg};
  border: ${borderWidth} solid ${({ theme }) => theme.btnBg};

  color: ${({ theme }) => theme.btnText};

  &:disabled {
    &:not(.loading) {
      color: ${({ theme }) => theme.btnTextDisabled};
    }
    background-color: ${({ theme }) => theme.btnBgDisabled};
    border: ${borderWidth} solid ${({ theme }) => theme.btnBgDisabled};
  }

  &:hover:not(:disabled):not(.loading) {
    background-color: ${({ theme }) => theme.btnBgHover};
    border: ${borderWidth} solid ${({ theme }) => theme.btnBgHover};
  }
`;

export const GhostButton = styled(BasicButton)`
  background: transparent;
  border: ${borderWidth} solid ${({ theme }) => theme.btnGhost};

  color: ${({ theme }) => theme.btnGhost};

  &:disabled {
    &:not(.loading) {
      color: ${({ theme }) => theme.btnGhostDisabled};
    }
    border: ${borderWidth} solid ${({ theme }) => theme.btnGhostDisabled};
  }

  &:hover:not(:disabled):not(.loading) {
    border: ${borderWidth} solid ${({ theme }) => theme.btnGhostHover};
    color: ${({ theme }) => theme.btnGhostHover};
  }
`;

export const Link = styled(BasicButton)`
  background: transparent;
  border: 0;
  color: ${({ theme }): string => theme.btnLink};
  text-decoration: underline;
`;

export const BtnLoader = styled.div`
  position: absolute;

  display: flex;
  align-items: center;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%) scale(0, 0);
  transition: all 0.1s ease-out;

  &.loading {
    transform: translate(-50%, -50%) scale(1, 1);
  }

  ${StyledLoader} {
    border-top: ${loaderWidth} solid ${({ theme }) => theme.btnLoaderBg};
    border-right: ${loaderWidth} solid ${({ theme }) => theme.btnLoaderBg};
    border-bottom: ${loaderWidth} solid ${({ theme }) => theme.btnLoaderBg};
    border-left: ${loaderWidth} solid ${({ theme }) => theme.btnLoader};
  }
`;

// =========================================================================
// Default Props
// =========================================================================

BasicButton.defaultProps = {
  theme: SaberTheme,
};

StyledButton.defaultProps = {
  theme: SaberTheme,
};

GhostButton.defaultProps = {
  theme: SaberTheme,
};

Link.defaultProps = {
  theme: SaberTheme,
};

BtnLoader.defaultProps = {
  theme: SaberTheme,
};
