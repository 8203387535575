import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import MenuContext from './Menu.Context';
import { CSSTransition } from 'react-transition-group';
import {
  Divider,
  Logo,
  Wrapper,
  Head,
  Container,
  MobileContainer,
  ScreenCover,
} from './Menu.styled';

import BtnHamburger from './Hamburger';
import Dropdown from './Dropdown';
import MenuItem from './MenuItem';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  activeId?: string;
  cover?: boolean;
  onMenuClick?: (id: string) => void;
  img?: string;
  imgObj?: React.ReactNode;
  shadow?: boolean;
}

function Menu({
  activeId,
  children,
  cover,
  className,
  img,
  imgObj,
  onMenuClick,
  shadow,
  ...otherDiv
}: Props) {
  const [open, setOpen] = useState(false);
  const [mobileChildren, setMobileChildren] = useState<
    JSX.Element[] | undefined | null
  >([]);
  const mobileRef = useRef(null);

  const toggleMenu = () => setOpen(!open);

  function screenClose() {
    setOpen(false);
  }

  useEffect(() => {
    function findItems(children: React.ReactNode): React.ReactNode {
      return React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement(child)) {
          if (child?.type === MenuItem) {
            return React.cloneElement(child);
          }

          if (child?.type === Dropdown) {
            const dropdownChilds = React.Children.map(
              child?.props.children,
              findItems,
            );
            return React.cloneElement(child, { children: dropdownChilds });
          }

          if (child?.props.children) {
            return findItems(child?.props.children);
          }
        }
        return null;
      });
    }

    const elements = findItems(children) as JSX.Element[];

    setMobileChildren(elements);
  }, [children]);

  useLayoutEffect(() => {
    function resized() {
      const { innerWidth } = window;

      if (innerWidth >= 1200) setOpen(false);
    }

    window.addEventListener('resize', resized);
    return () => window.removeEventListener('resize', resized);
  });

  return (
    <React.Fragment>
      <Wrapper
        className={`navigation-bar ${className || ''} ${
          shadow ? 'shadow' : ''
        }`}
        {...otherDiv}
      >
        <MenuContext.Provider
          value={{
            activeId,
            changeActiveId: (value) => {
              if (onMenuClick) onMenuClick(value);
              if (open) setOpen(false);
            },
          }}
        >
          <Head className="head">
            <div className="col-1">
              <BtnHamburger open={open} onClick={toggleMenu} />
            </div>
            <div className="col-2">
              {imgObj || <Logo src={img} alt="logo" />}
            </div>
            <div className="col-3"></div>
            <Container className="desktop-container" data-cy="desktop-menu">
              {children}
            </Container>
            <CSSTransition
              in={open}
              timeout={300}
              nodeRef={mobileRef}
              className="mobile-container"
              classNames="mobile"
            >
              <MobileContainer ref={mobileRef} data-cy="mobile-menu">
                {mobileChildren}
              </MobileContainer>
            </CSSTransition>
          </Head>
        </MenuContext.Provider>
      </Wrapper>
      {open && cover ? <ScreenCover onClick={screenClose} /> : null}
    </React.Fragment>
  );
}

Menu.Divider = Divider;
Menu.Dropdown = Dropdown;
Menu.Item = MenuItem;

export default Menu;
