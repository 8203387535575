import global from './Globals';

const Form = {
  formHeaderText: global.black,
  formGroupText: global.primary500,
  formGroupTextError: global.error400,
  formGroupInput: global.black,
  formGroupInputActive: global.primary500,
  formGroupBorder: 'rgba(0, 0, 0, 0.25)',
  formGroupBorderError: global.error400,
  formGroupBg: 'transparent',
  formGroupBgDisabled: global.grey300,
  formGroupFocusBorder: global.primary500,
  formGroupFocusGlow: global.primary200,
  eyeColour: 'rgba(255, 255, 255, 0.33)',
  eyeColourHover: 'rgba(255, 255, 255, 1)',
};

export default Form;
