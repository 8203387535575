import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

const ANIM_TIME = '100ms';
export const inputRadius = '3px';

export const Optional = styled.span`
  color: grey;
  font-family: ${({ theme }) => theme.fontBody};
  padding-left: 0.25em;
`;

interface LabelProps {
  ref: React.MutableRefObject<null>;
}

export const Label = styled.label<LabelProps>`
  color: ${({ theme }) => theme.grey500};
  cursor: text;
  display: block;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};

  top: 0.75rem;
  left: 0.75rem;

  position: absolute;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &.label-enter-active {
    transition: all ${ANIM_TIME} ease-out;
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25rem;
  }

  &.label-enter-done {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25rem;
  }

  &.label-exit-enter {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25rem;
  }

  &.label-exit {
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeSmall};
    top: 0.25rem;
  }

  &.label-exit-active {
    transition: all ${ANIM_TIME} ease-out;
    color: ${({ theme }) => theme.formGroupText};
    font-size: ${({ theme }) => theme.fontSizeBase};
    top: 0.75rem;
  }

  &.label-exit-done {
    font-size: ${({ theme }) => theme.fontSizeBase};
    top: 0.75rem;
  }
`;

interface FormInputProps {
  error?: boolean;
}

export const StyledTextarea = styled.textarea<FormInputProps>`
  background-color: ${({ theme }) => theme.formGroupBg};
  border: 1px solid
    ${({ theme, error }) => {
      if (error) {
        return theme.formGroupBorderError;
      }
      return theme.formGroupBorder;
    }};
  border-radius: ${inputRadius};
  box-sizing: border-box;

  color: ${({ theme }) => theme.formGroupInput};

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};
  padding: 1.5em 0.75em 0.5em 0.7em;
  width: 100%;

  &.password {
    border-radius: ${inputRadius} 0 0 ${inputRadius};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.formGroupFocusBorder};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.formGroupFocusGlow};
    outline: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.formGroupBgDisabled};
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

export const WrapperFormInput = styled.div`
  display: flex;
  position: relative;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.formGroupTextError};

  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${({ theme }) => theme.fontSizeBase};

  height: 20px;
  padding: 0.5em 0;
  overflow: hidden;
  transition: all 0.1s ease-out;
  opacity: 1;

  &[data-hidden='true'] {
    opacity: 0;
  }
`;

// =========================================================================
// Default Props
// =========================================================================
Optional.defaultProps = {
  theme: SaberTheme,
};

Label.defaultProps = {
  theme: SaberTheme,
};

StyledTextarea.defaultProps = {
  theme: SaberTheme,
};

ErrorMessage.defaultProps = {
  theme: SaberTheme,
};
