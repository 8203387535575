import React from 'react';
import { BtnHamburger } from './Hamburger.styled';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  open?: boolean;
}

function Hamburger({ open, ...btnProps }: Props): React.ReactElement {
  return (
    <BtnHamburger
      {...btnProps}
      className={open ? 'hamburger open' : 'hamburger'}
      data-cy="hamburger-menu"
      aria-label="hamburger"
    >
      <span />
      <span />
      <span />
    </BtnHamburger>
  );
}

Hamburger.defaultProps = {
  open: false,
};

export default Hamburger;
