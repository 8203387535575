import Globals from '../Globals';

const LightGlobal = {
  ...Globals,

  primary800: '#301d00',
  primary700: '#794801',
  primary600: '#c17201',
  primary500: '#f18f01',
  primary400: '#f5b14d',
  primary300: '#f8c780',
  primary200: '#fbddb3',
  primary100: '#fef4e6',

  secondary100: '#e6eff6',
  secondary200: '#b3cfe4',
  secondary300: '#669fca',
  secondary400: '#337fb8',
  secondary500: '#005fa6',
  secondary600: '#004c85',
  secondary700: '#004374',
  secondary800: '#003053',
  secondary900: '#001c32',
};

export default LightGlobal;
