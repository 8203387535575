import React from 'react';

import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
} from './Header.styled';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  /** How big should the header be? */
  level: number;
}

function Header({ children, level, ...hProps }: Props) {
  const clampedLevel = Math.max(1, Math.min(level, 5));

  switch (clampedLevel) {
    case 2:
      return <StyledH2 {...hProps}>{children}</StyledH2>;
    case 3:
      return <StyledH3 {...hProps}>{children}</StyledH3>;
    case 4:
      return <StyledH4 {...hProps}>{children}</StyledH4>;
    case 5:
      return <StyledH5 {...hProps}>{children}</StyledH5>;
  }
  return <StyledH1 {...hProps}>{children}</StyledH1>;
}

Header.defaultProps = {
  level: 1,
};

export default Header;
