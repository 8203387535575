import React from 'react';

import Checkbox from './Checkbox';
import FormHeader from './FormHeader';
import FormGroup from './FormGroup';
import Textarea from './Textarea';
import Validator from './Validator';

import { StyledForm } from './Form.styled';

import { DataType, State } from './Form.types';

interface Props {
  /** The id of the form */
  id?: string;

  /** Clear all inputs on submit */
  clearOnSubmit?: boolean;

  /** Callback for when the form is submitted */
  onSubmit: (data: Record<string, unknown>) => void;
}

class Form extends React.Component<Props, State> {
  static Checkbox = Checkbox;

  static Header = FormHeader;

  static Input: typeof FormGroup = FormGroup;

  static Textarea: typeof Textarea = Textarea;

  static Validator = Validator;

  static defaultProps = {
    clearOnSubmit: false,
  };

  state: State = {
    data: {},
    _activated: false,
    _cleared: false,
    _mountFinished: false,
  };

  componentDidMount(): void {
    const { children } = this.props;

    const data: {
      [key: string]: DataType;
    } = {};

    // Recursively check the elements to see if there is Validator element
    const checkForValidators = (child: React.ReactNode): void => {
      if (React.isValidElement(child)) {
        const { props, type } = child;

        if (type === Validator) {
          const newData: DataType = {
            value: props.defaultValue || '',
            validated: false,
            required: props.required || false,
          };

          data[props.name] = newData;
        } else if (type !== FormHeader) {
          if (props.children) {
            React.Children.map(props.children, checkForValidators);
          }
        }
      }
    };

    React.Children.map(children, checkForValidators);
    this.setState({ data: { ...data }, _mountFinished: true });
  }

  componentDidUpdate(_: Props, prevState: State): void {
    const { _cleared, data } = this.state;

    if (prevState._cleared !== _cleared && _cleared) {
      // Immediately reset the private flags to false
      this.setState({ _activated: false, _cleared: false, data: { ...data } });
    }
  }

  onChange = (name: string, value: string): void => {
    const { data } = this.state;

    if (data[name]) {
      data[name].value = value;
      this.setState({ data: { ...data } });
    }
  };

  informInvalid = (name: string, invalid: boolean): void => {
    const { data } = this.state;

    if (data[name]) {
      data[name].validated = !invalid;
      this.setState({ data: { ...data } });
    }
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const { clearOnSubmit, onSubmit } = this.props;
    const { data } = this.state;

    this.setState({ _activated: true });

    const failed = Object.keys(data).some((key) => {
      const obj = data[key];

      if (!obj.value && obj.required) {
        return true;
      } else if (!obj.value && !obj.required) {
        return false;
      }

      if (!obj.validated) {
        return true;
      }

      return false;
    });

    if (!failed) {
      if (clearOnSubmit) {
        this.setState({ _cleared: true });
      }

      // Strip out all of the unnecessary data such as validated, required
      const santised = {};

      Object.keys(data).forEach((key) => (santised[key] = data[key].value));

      onSubmit(santised);
    }
  };

  render(): React.ReactNode {
    const { children, ...otherFormProps } = this.props;
    const { _activated, _cleared, _mountFinished } = this.state;

    const updateChildren = (child: React.ReactNode): React.ReactNode => {
      if (React.isValidElement(child)) {
        const { props, type } = child;

        if (type === Validator) {
          const { defaultValue } = props;

          return React.cloneElement(child, {
            _activated: _activated || (defaultValue.length && _mountFinished),
            _cleared,
            onChange: this.onChange,
            informInvalid: this.informInvalid,
          });
        } else {
          if (props.children) {
            return React.cloneElement(child, {
              children: React.Children.map(props.children, updateChildren),
            });
          }
          return child;
        }
      }
      return child;
    };

    const clonedChildren = React.Children.map(children, updateChildren);

    return (
      <StyledForm {...otherFormProps} onSubmit={this.onSubmit}>
        {clonedChildren}
      </StyledForm>
    );
  }
}

export default Form;
