import React from 'react';

import StyledCheckbox from './Checkbox.styled';

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  /** Text to describe the input */
  label: string;

  /** Name of the input */
  name?: string;

  /** Update the validator */
  updateValue?: (value: string | number) => void;

  /** Value of the input */
  value?: string | number;

  /** Is input required? */
  required?: boolean;
}

const Checkbox: React.FunctionComponent<Props> = ({
  label,
  name,
  updateValue,
  value,
  required,
  ...inputProps
}: Props) => {
  function onChange(e: React.FormEvent<HTMLInputElement>): void {
    const { checked } = e.target as HTMLInputElement;

    if (updateValue) {
      updateValue(checked ? 'true' : 'false');
    }
  }

  if (required) {
    throw new Error('Checkboxes should not be assigned a required flag.');
  }

  return (
    <StyledCheckbox>
      <label htmlFor={name}>{label}</label>{' '}
      <input
        name={name}
        onChange={onChange}
        value="true"
        checked={value === 'true'}
        {...inputProps}
        type="checkbox"
      />
    </StyledCheckbox>
  );
};

export default Checkbox;
