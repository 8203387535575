import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { StyledStepsItem, Description, Icon } from './StepsItem.styled';

export interface Props {
  /** What step is this? The first step? */
  index?: number;

  /** Step has been completed */
  completed?: boolean;

  /** The text displayed */
  description: string;

  /** Is the item disabled */
  disabled?: boolean;

  /** Step has encountered an error */
  error?: boolean;

  /** The icon you want to show off */
  icon?: React.ReactNode;

  /** When a step item is clicked */
  onClick?: () => void;

  /** Are we currently at this step? */
  progress?: boolean;

  /** Are we going to show a border that expands to the right of the icon */
  showBorder?: boolean;
}

const StepItem: React.FC<Props> = (props: Props) => {
  const {
    index,
    completed,
    description,
    disabled,
    error,
    icon,
    onClick,
    progress,
    showBorder,
  } = props;

  const handleClick = (): void => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  let iconElement: React.ReactNode = index;

  if (error) {
    iconElement = <FaTimes />;
  } else if (completed) {
    iconElement = <FaCheck />;
  } else if (icon) {
    iconElement = icon;
  }

  const statusProps = {
    completed,
    error,
    progress,
    disabled,
  };

  return (
    <StyledStepsItem
      onClick={handleClick}
      showBorder={showBorder}
      {...statusProps}
    >
      <Icon {...statusProps}>{iconElement}</Icon>
      <Description {...statusProps}>{description}</Description>
    </StyledStepsItem>
  );
};

StepItem.defaultProps = {
  index: -1,
  completed: false,
  description: '',
  disabled: false,
  error: false,
  icon: null,
  onClick: undefined,
  progress: false,
  showBorder: true,
};

export default StepItem;
