import React, { useEffect, useState, useContext, useRef } from 'react';
import { MdChevronRight } from 'react-icons/md';

import MenuContext from '../Menu.Context';
import MenuItem from '../MenuItem/MenuItem.styled';
import { Chevron, DropdownArea, Wrapper } from './Dropdown.styled';

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  className,
  children,
  label,
}: DropdownProps) => {
  const [childLinks, setChildLinks] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState(false);
  const context = useContext(MenuContext);
  const ref = useRef<HTMLDivElement>(null);

  function detectClick(event: Event) {
    if (!ref?.current?.contains(event.target as Node)) {
      setExpanded(false);
    }
  }

  function toggleExpand() {
    setExpanded(!expanded);
  }

  useEffect(() => {
    if (children) {
      const tos = React.Children.map(children, (child: React.ReactNode) => {
        if (child && typeof child !== 'string') {
          const { to } = (child as JSX.Element).props;
          return to;
        }
      });

      if (tos) setChildLinks(tos);
    }
  }, [children]);

  useEffect(() => {
    document.addEventListener('mousedown', detectClick);
    return () => {
      document.removeEventListener('mousedown', detectClick);
    };
  }, []);

  const isActive = childLinks.some((to) => to === context.activeId);

  return (
    <Wrapper className={expanded ? 'expanded' : ''} ref={ref}>
      <MenuItem
        className={`menu-dropdown ${className || ''} ${
          isActive ? 'active' : ''
        }`}
        onClick={toggleExpand}
      >
        <Chevron>
          <MdChevronRight />
        </Chevron>
        {label}
      </MenuItem>
      <DropdownArea>{children}</DropdownArea>
    </Wrapper>
  );
};

export default Dropdown;
