import styled from 'styled-components';
import MenuItem from './MenuItem/MenuItem.styled';

import SaberTheme from '../Themes/SaberLight';

export const MenuBreakpoint = '1200px';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.menuBgColour};
  position: relative;

  &.shadow {
    box-shadow: ${({ theme }) => theme.menuShadow};
  }
`;

export const Head = styled.div`
  display: flex;
  position: relative;

  max-width: 1300px;
  margin: auto;

  .col-1 {
    display: flex;
    align-items: center;
    flex-basis: 20%;
  }

  .col-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 60%;
  }

  .col-3 {
    flex-basis: 20%;
  }

  @media only screen and (min-width: ${MenuBreakpoint}) {
    .col-1,
    .col-3 {
      display: none;
    }
    .col-2 {
      flex-basis: 0%;
    }
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: none;

  ${MenuItem}.active::after {
    background: ${({ theme }) => theme.menuItemActive};
    position: absolute;
    bottom: 0px;
    left: 0.5rem;
    right: 0.5rem;
    content: '';
    height: 3px;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }

  @media only screen and (min-width: ${MenuBreakpoint}) {
    display: flex;
    align-items: stretch;
  }
`;

export const MobileContainer = styled.div`
  background-color: ${({ theme }) => theme.menuMobileBg};
  box-sizing: border-box;
  position: absolute;
  width: 0;
  top: 100%;
  overflow-x: hidden;
  padding: 0.5rem 0;

  z-index: 1200;

  &.mobile-enter {
    padding: 0.5rem 0;
  }

  &.mobile-enter-active {
    padding: 0.5rem;
    transition: all 300ms;
    width: 100%;
  }

  &.mobile-enter-done {
    padding: 0.5rem;
    width: 100%;
  }

  &.mobile-exit {
    padding: 0.5rem;
    width: 100%;
  }

  &.mobile-exit-active {
    padding: 0.5rem 0;
    width: 0;
    transition: all 300ms;
  }

  @media only screen and (min-width: ${MenuBreakpoint}) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 32px;
  padding: 0.5rem;
`;

export const Divider = styled.span`
  border-left: 1px solid ${({ theme }) => theme.menuDivider};
  display: none;
  margin: 0.8rem;

  @media only screen and (min-width: 1200px) {
    display: block;
  }
`;

export const ScreenCover = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1199;
`;

// =======================================================
// Default Props
// =======================================================

Wrapper.defaultProps = {
  theme: SaberTheme,
};

Divider.defaultProps = {
  theme: SaberTheme,
};

MobileContainer.defaultProps = {
  theme: SaberTheme,
};
