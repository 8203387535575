import global from './Globals';

const Button = {
  btnBg: global.primary500,
  btnBgDisabled: global.primary200,
  btnBgHover: global.primary600,

  btnText: global.white,
  btnTextDisabled: global.primary400,

  btnGhost: global.black,
  btnGhostHover: global.grey400,
  btnGhostDisabled: global.grey700,

  btnLink: global.secondary300,

  btnLoaderBg: global.white,
  btnLoader: global.primary400,
};

export default Button;
