import React from 'react';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from 'react-icons/fa';
import { Container, Content, Icon } from './Alert.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  success?: boolean;
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  icon?: React.ReactNode;
}

const Alert: React.FunctionComponent<Props> = ({
  className,
  success,
  error,
  warning,
  info,
  icon,

  children,

  ...divProps
}: Props) => {
  let typeClass = '';
  let iconElement: React.ReactNode | null = null;

  if (success) {
    typeClass = 'success';
    iconElement = <FaCheckCircle />;
  } else if (error) {
    typeClass = 'error';
    iconElement = <FaTimesCircle />;
  } else if (warning) {
    typeClass = 'warning';
    iconElement = <FaExclamationTriangle />;
  } else if (info) {
    typeClass = 'info';
    iconElement = <FaExclamationCircle />;
  }

  if (icon) iconElement = icon;

  return (
    <Container className={`${className || ''} ${typeClass}`} {...divProps}>
      <Icon>{iconElement}</Icon>
      <Content>{children}</Content>
    </Container>
  );
};

export default Alert;
