import React, { useState } from 'react';
import InternalPhoneInput, { PhoneInputProps } from 'react-phone-number-input';
import { FaExclamationCircle } from 'react-icons/fa';

import {
  ErrorMessage,
  Label,
  Optional,
  StyledPhoneInput,
} from './PhoneInput.styled';

interface Props
  extends Omit<Omit<Omit<PhoneInputProps, 'onChange'>, 'error'>, 'value'> {
  /** Has the input validation failed? */
  error?: boolean;

  /** The string that will show when input has failed. */
  errorMessage?: string;

  /** Text to describe the input */
  label: string;

  /**
   * __NOT USED__ The validator will always give this to the element.
   * Force this to be undefined to avoid it been placed onto the div element.
   */
  loading?: boolean;

  /** Name of the input */
  name: string;

  /** Is input required? */
  required?: boolean;

  /** Is input disabled? */
  disabled?: boolean;

  /** Call back when you no longer focus on the input */
  onBlur?: (event: React.FocusEvent<string>) => void;

  /** Validator specific code. */
  updateValue?: (value: string | number) => void;
}

function PhoneInput(props: Props) {
  const {
    error,
    errorMessage,
    label,
    name,
    required,
    disabled,
    onBlur,
    updateValue,
    ...inputProps
  } = props;

  const [value, setValue] = useState('');

  function onChange(value: string): void {
    setValue(value);
    if (updateValue && !disabled) {
      updateValue(value);
    }
  }

  const optional = required ? '' : <Optional>(Optional)</Optional>;

  let labelElement = null;

  if (label) {
    labelElement = (
      <Label htmlFor={name}>
        {label}
        {optional}
      </Label>
    );
  }

  inputProps.loading = undefined;

  return (
    <React.Fragment>
      <StyledPhoneInput disabled={disabled}>
        {labelElement}
        <InternalPhoneInput
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          {...inputProps}
          value={value}
        />
      </StyledPhoneInput>
      <ErrorMessage data-hidden={!error}>
        <FaExclamationCircle />
        &nbsp;{errorMessage}
      </ErrorMessage>
    </React.Fragment>
  );
}

PhoneInput.defaultProps = {
  error: false,
  errorMessage: null,
  label: '',
  name: '',
  required: false,
  disabled: false,
};

export default PhoneInput;
