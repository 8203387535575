import styled from 'styled-components';
import SaberTheme from '../../Themes/SaberLight';

const StyledCheckbox = styled.div`
  color: ${({ theme }): string => theme.fontColour};
  font-family: ${({ theme }) => theme.fontBody};
  margin-bottom: 1em;

  display: flex;
  align-items: flex-end;
  gap: 0.5rem;

  input {
    margin: 0.2rem 0;
  }
`;

StyledCheckbox.defaultProps = {
  theme: SaberTheme,
};

export default StyledCheckbox;
