import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { FaExclamationCircle } from 'react-icons/fa';

import {
  ErrorMessage,
  Label,
  Optional,
  StyledTextarea,
  WrapperFormInput,
} from './Textarea.styled';

export interface TAProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  /** Has the input validation failed? */
  error?: boolean;

  /** The string that will show when input has failed. */
  errorMessage?: string;

  /** Text to describe the input */
  label: string;

  /**
   * __NOT USED__ The validator will always give this to the element.
   * Force this to be undefined to avoid it been placed onto the div element.
   */
  loading?: boolean;

  /** Flag to determine or not to show the error on change or blur */
  showErrorOnChange?: boolean;

  /** Name of the input */
  name?: string;

  /** Update the validator */
  updateValue?: (value: string | number) => void;

  /** Value of the input */
  value?: string | number;

  /** Theme object provided by <ThemeProvider> */
  theme: object;

  /** Is input required? */
  required?: boolean;

  /** Show error message */
  _activated?: boolean;
}

const Textarea: React.FunctionComponent<TAProps> = (props) => {
  const [hideLabel, setHideLabel] = useState(false);
  const [showError, setShowError] = useState(false);
  const labelRef: React.MutableRefObject<null> = useRef(null);

  const {
    className,
    error,
    errorMessage,
    label,
    name,
    required,
    showErrorOnChange,
    value,
    theme,
    updateValue,
    _activated,
    ...inputProps
  } = props;

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const { value } = event.target as HTMLTextAreaElement;

    setShowError(showErrorOnChange || false);

    if (updateValue) {
      updateValue(value);
    }
  }

  function onFocus(): void {
    setHideLabel(true);
  }

  function wrapOnBlur(e: React.FormEvent<HTMLTextAreaElement>): void {
    const { value } = e.target as HTMLTextAreaElement;

    setHideLabel(value.length > 0);
    setShowError(true);
  }

  const optional = required ? '' : <Optional>(Optional)</Optional>;

  let labelElement = null;

  if (label) {
    labelElement = (
      <Label htmlFor={name} ref={labelRef}>
        {label}
        {optional}
      </Label>
    );
  }

  inputProps.loading = undefined;

  useEffect(() => {
    if (value) {
      setHideLabel(true);

      const node = labelRef.current;

      if (node) {
        const label = node as HTMLElement;
        if (!label.classList.contains('label-exit')) {
          label.classList.add('label-exit');
        }
      }
    } else {
      setHideLabel(false);
    }

    if (_activated) {
      setShowError(true);
    }
  }, [error, value, theme, _activated]);

  return (
    <React.Fragment>
      <WrapperFormInput>
        <CSSTransition
          appear
          in={hideLabel}
          nodeRef={labelRef}
          timeout={100}
          classNames="label"
        >
          {labelElement}
        </CSSTransition>
        <StyledTextarea
          id={name}
          className={className || ''}
          error={showError && error}
          name={name}
          value={value}
          data-testid="form-input"
          onChange={onChange}
          {...inputProps}
          onBlur={wrapOnBlur}
          onFocus={onFocus}
          placeholder={undefined}
        />
      </WrapperFormInput>
      <ErrorMessage data-hidden={!(showError && error)}>
        <FaExclamationCircle />
        &nbsp;{errorMessage}
      </ErrorMessage>
    </React.Fragment>
  );
};
Textarea.defaultProps = {
  label: '',
  name: '',
  theme: {},
  _activated: false,
};

export default withTheme(Textarea);
